<template>
  <div>
    <v-card elevation="0" outlined color="#f1f1f1" :loading="requestProcessing">
      <template slot="progress">
        <v-progress-linear height="10" indeterminate></v-progress-linear>
        <p class="mt-4 text-center">Processing...</p>
      </template>
      <v-card-title v-if="!requestProcessing">
        <v-row>
          <v-col cols="12" md="6">New Message </v-col>
          <v-col cols="12" md="6" class="d-flex justify-end">
            <router-link class="blue--text mx-1" :to="`/message-center`">
              <v-btn small>Cancel</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!requestProcessing" class="mt-3">
        <v-form>
          <v-container>
            <v-row>
              <!-- <v-col cols="12" md="1">
                
              </v-col> -->
              <v-col cols="12" md="6">
                <h6 class="font-weight-bold">Send To</h6>
                <v-select
                  v-model="sendTo"
                  :items="sendToItems"
                  item-text="name"
                  item-value="value"
                  label="Choose group..."
                ></v-select>
                <v-autocomplete
                  :loading="loadingRecipients"
                  v-model="newMessage.sendTo"
                  v-if="showSendToLine"
                  :items="recipients"
                  item-text="fullName"
                  item-value="id"
                  dense
                  chips
                  small-chips
                  label="Send To"
                  multiple
                  solo
                  :error-messages="sendToErrors"
                  :required="showSendToLine"
                ></v-autocomplete>
                <v-container v-if="showSendByBranch">
                  <v-autocomplete
                    v-model="newMessage.sendToBranch"
                    :items="branches"
                    dense
                    chips
                    small-chips
                    label="Send To Branch"
                    multiple
                    solo
                    :error-messages="sendToErrors"
                    :required="showSendByBranch"
                  ></v-autocomplete>
                </v-container>
                <!-- <v-text-field
                  v-if="showSendToLine()"
                  v-model="newMessage.sendTo"
                  label="Send To"
                  required
                ></v-text-field> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newMessage.subject"
                  label="Subject"
                  :error-messages="subjectErrors"
                  @input="$v.newMessage.subject.$touch()"
                  @blur="$v.newMessage.subject.$touch()"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="newMessage.body"
                  label="Message"
                  :error-messages="bodyErrors"
                  @input="$v.newMessage.body.$touch()"
                  @blur="$v.newMessage.body.$touch()"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <div
                  v-for="attachment in attachmentsObj"
                  cols="12"
                  class="darggable-col"
                  :key="attachment.id"
                >
                  <MediaUploader
                    class="pb-5"
                    uploadType="file"
                    :key="attachment.id"
                    :id="`multiFilePost_${attachment.id}`"
                    :index="attachment.id"
                    :accept="accept"
                    :multiple="true"
                    :extensions="extensions"
                    :sizeImage="10485760"
                    :sizeVideo="104857600"
                    :fileId.sync="attachment.fileId"
                    :file-url.sync="attachment.url"
                    :isUploading.sync="isFileUploading"
                    @onMultipleUploaded="onMultipleAddFile"
                    @onChange="onChange"
                    @onDeleteFile="onDeleteFile"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-radio-group v-model="newMessage.isActionRequired" row>
                  <template v-slot:label>
                    <div>Action Required:</div>
                  </template>
                  <v-radio label="Yes" value="1"></v-radio>
                  <v-radio label="No" value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-btn color="orange-bg white--text" @click="sendMessage">
              Send Message
            </v-btn>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { GET_PAGED, POST_DATA, API_USERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  GET_QUERY,
  POST_DATA,
  API_EMPLOYEES,
  API_CUSTOMERS,
  API_MESSAGES
} from "@/core/store/crud.module";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import permissionsHelper from "@/core/helpers/permissionsHelper";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  components: {
    MediaUploader
  },
  data: () => ({
    isLoading: false,
    newMessage: {
      sendTo: [],
      sendToBranch: [],
      recipients: null,
      subject: "",
      body: "",
      attachments: [],
      isActionRequired: "0"
    },
    showSendToLine: true,
    showSendByBranch: false,
    sendTo: "YN team member or customer",
    sendToItems: [
      {
        name: "YN team member or customer",
        value: "YN team member or customer"
      },
      {
        name: "My YN Team and my customers",
        value: "My YN Team and my customers"
      },
      { name: "My YN Team", value: "My YN Team" },
      { name: "My customers", value: "My customers" },
      { name: "Send by branch", value: "Send by branch" }
    ],
    recipients: [],
    customers: [],
    branches: [],
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    // extensions: "undefined",
    // accept: "undefined",
    loadingRecipients: false,
    requestProcessing: false
  }),
  validations() {
    return {
      sendTo: {
        required
      },
      newMessage: {
        subject: {
          required
        },
        body: {
          required
        },
        recipients: {
          required,
          minLength: 1
        }
      }
    };
  },
  watch: {
    // userQuery: async function() {
    //   if (this.userQuery && this.userQuery.length > 0) {
    //     await this.getUsers();
    //   } else {
    //     this.users = [];
    //   }
    // },
    // "options.sortBy": async function() {
    //   await this.getDataFromApi();
    //   // if (this.userQuery && this.userQuery.length > 0) {
    //   //   await this.getUsers();
    //   // } else {
    //   //   this.users = [];
    //   // }
    // },
    sendTo: function() {
      this.setShowSendToLine();
    }
  },
  async created() {
    this.getRecipients();
    permissionsHelper.getPermissions().then(this.getPermissions);
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Message Center", route: "/message-center" },
      { title: "Send New Message" }
    ]);
    this.setShowSendToLine();

    if (this.$route.query && this.$route.query.recipient) {
      if (this.$route.query.recipient === "MyYNTeam")
        this.sendTo = "My YN Team";
      else if (this.sendTo === "YN team member or customer") {
        for (let i = 0; i < this.recipients.length; i++) {
          if (this.$route.query.recipient == this.recipients[i].id)
            this.newMessage.sendTo.push(this.recipients[i]);
        }
      }
    }
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;

      this.sendToItems = [
        {
          name: "YN team member or customer",
          value: "YN team member or customer"
        },
        {
          name: "My YN Team and my customers",
          value: "My YN Team and my customers"
        },
        { name: "My customers", value: "My customers" }
      ];
      if (this.permissions.message.canSendMyTeam) {
        this.sendToItems.push({ name: "My YN Team", value: "My YN Team" });
      }
      if (this.permissions.message.canSendByBranch) {
        this.sendToItems.push({
          name: "Send by branch",
          value: "Send by branch"
        });
      }
    },
    async getRecipients() {
      this.loadingRecipients = true;
      // let comunityItems = dataModels.communityDetails;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_EMPLOYEES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.recipients = response.data;

            let recipient = self.recipients.find(
              ({ id }) => id === self.$route.query.recipient
            );
            self.newMessage.sendTo.push(recipient);
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive recipients. Something went wrong!",
              color: "red"
            });
          }

          this.loadingRecipients = false;
        });
    },
    async getCustomers() {
      this.loadingCustomers = true;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data;

            self.branches = [];
            items.forEach(el => {
              self.branches.push({
                name: el.branchName,
                value: el.branchName
              });
            });

            self.customers = items;
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot get a list of branches. Something went wrong!",
              color: "red"
            });
          }

          self.loadingCustomers = false;
        });
    },
    setShowSendToLine() {
      this.showSendToLine = this.sendTo === "YN team member or customer";
      this.showSendByBranch = this.sendTo === "Send by branch";
    },
    sendMessage() {
      let mgr = new Mgr();

      this.newMessage.isNew = true;
      this.newMessage.isActionRequired =
        this.newMessage.isActionRequired == "1";
      let self = this;
      mgr.getRole().then(function(role) {
        switch (self.sendTo) {
          case "YN team member or customer":
            self.newMessage.recipients = self.newMessage.sendTo.map(el => {
              return { id: el };
            });
            break;
          case "My YN Team and my customers":
            self.newMessage.recipients = self.customers.map(el => {
              return { id: el };
            });
            if (role == "Branch Manager" || role == "Regional Manager") {
              self.branches.forEach(el => {
                let ynRecipients = self.recipients.filter(
                  r =>
                    r.hasAllBranchAccess ||
                    r.branches.$values.filter(b => b.name == el).length > 0
                );

                self.newMessage.recipients = ynRecipients.map(r => {
                  return { id: r.id };
                });
              });
            }
            break;
          case "My YN Team":
            if (role == "Branch Manager" || role == "Regional Manager") {
              self.branches.forEach(el => {
                let ynRecipients = self.recipients.filter(
                  r =>
                    r.hasAllBranchAccess ||
                    r.branches.$values.filter(b => b.name == el).length > 0
                );

                self.newMessage.recipients = ynRecipients.map(r => {
                  return { id: r.id };
                });
              });
            } else if (role == "Executive") {
              self.newMessage.recipients = self.recipients.map(r => {
                return { id: r.id };
              });
            }
            break;
          case "My customers":
            self.newMessage.recipients = self.customers.map(el => {
              return { id: el };
            });
            break;
          case "Send by branch":
            self.newMessage.sendToBranch.forEach(el => {
              let ynRecipients = self.recipients.filter(
                r =>
                  r.hasAllBranchAccess ||
                  r.branches.$values.filter(b => b.name == el).length > 0
              );

              self.newMessage.recipients = ynRecipients.map(r => {
                return { id: r.id };
              });
            });

            break;
        }

        self.newMessage.attachments = self.attachmentsObj
          .filter(i => i.url && i.url.length > 0)
          .map(i => {
            return { id: i.fileId, fileUrl: i.url };
          });
        self.newMessage.attachments = self.newMessage.attachments.filter(
          (item, index) => self.newMessage.attachments.indexOf(item) === index
        ); //remove duplicates

        self.$v.$touch();
        if (self.$v.$invalid) {
          return;
        }

        self.requestProcessing = true;

        self.$store
          .dispatch(POST_DATA, {
            listName: `${API_MESSAGES}`,
            data: self.newMessage
          })
          .then(response => {
            if (response.status >= 200 && response.status <= 204) {
              self.snackbarContent = "Message has been sent";

              self.$router.push({
                name: "MessageCenter"
              });
            } else {
              self.snackbarContent = response.data;
              self.snackbarColor = "red";
            }
            self.$snackbar.showMessage({
              content: self.snackbarContent,
              color: self.snackbarColor
            });

            self.requestProcessing = false;
          });
      });
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    },
    sendToErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.recipients.$dirty) return errors;
      !this.$v.newMessage.recipients.required && errors.push("Required.");
      !this.$v.newMessage.recipients.minLength && errors.push("Required.");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.subject.$dirty) return errors;
      !this.$v.newMessage.subject.required && errors.push("Required.");
      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.body.$dirty) return errors;
      !this.$v.newMessage.body.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
